import React from 'react'
import * as styles from './button-primary.module.scss';

import { Link } from 'gatsby';

import { useLocalPath } from '../../../hooks';

const ButtonPrimary = ({
    link,
    url,
    disableTarget,
    clickHandler,
    text,
    extraClass
}: {
    link?: string,
    url?: string,
    disableTarget?: boolean,
    clickHandler?: any,
    text: string,
    extraClass?: any
}) => {
    if (link) {
        return (
            <Link
                to={useLocalPath(link)}
                className={`${extraClass} ${styles.buttonPrimary}`}>
                {
                    text.split('/n').map((phrase, key) => (
                        <p key={key} style={{ display: 'block' }}>{phrase}</p>
                    ))
                }
            </Link>
        )
    } else if (url) {
        return (
            <a
                className={`${extraClass} ${styles.buttonPrimary}`}
                href={url}
                target={disableTarget ? '' : '_blank'}
                rel="noopener">
                {text}
            </a>
        )
    } else if (clickHandler) {
        return (
            <button
                className={`${extraClass} ${styles.buttonPrimary}`}
                onClick={() => { clickHandler && clickHandler() }}>
                {text}
            </button>
        )
    } else {
        return (
            <button className={`${extraClass} ${styles.buttonPrimary}`}>
                {text}
            </button>
        )
    }
}

export default ButtonPrimary