import React from 'react'
import ButtonPrimary from '../Shared/ButtonPrimary/ButtonPrimary'
import * as styles from './not-found.module.scss'

const NotFound = () => {
    return (
        <div className={styles.container}>
            <ButtonPrimary text='Return Home Page' link='/' />
        </div>
    )
}

export default NotFound