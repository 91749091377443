import { graphql } from 'gatsby'
import React from 'react'

import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import NotFound from '../components/NotFound/NotFound';

const PageNotFound = ({
    pageContext,
    location,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
            slug,
            sections
        }
    }
}) => {
    // console.log(sections);

    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                lang={pageContext.node_locale}
            />
            <div className='content'>
                {
                    sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroComponent data={section} index={index} key={index} fullSize={true} />
                                )
                        }
                    })
                }
                <div>
                    <NotFound />
                </div>
            </div>

        </div>
    )
}

export const PageNotFoundQuery = graphql`
    query PageNotFoundQuery($id: String!){
        contentfulPageCustom(id: {eq: $id}){
            seoTitle
            slug
            seoDescription {
                seoDescription
            }
            sections {
                __typename
                ...componentHero
            }
        }
    }
`

export default PageNotFound